<script setup lang="ts">
import { setUser } from "@sentry/nuxt";

const colorMode = useColorMode();

const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white",
);

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
    { name: "apple-mobile-web-app-title", content: "Teslemetry" },
  ],
  link: [
    { rel: "icon", href: "/favicon.ico" },
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon-96x96.png",
      sizes: "96x96",
    },
    { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" },
    { rel: "shortcut icon", href: "/favicon.ico" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    { rel: "manifest", href: "/site.webmanifest" },
  ],
  htmlAttrs: {
    lang: "en",
  },
});

useSeoMeta({
  titleTemplate: "%s - Teslemetry",
  //ogImage: "https://teslemetry.com/social-card.png",
  //twitterImage: "https://teslemetry.com/social-card.png",
  //twitterCard: "summary_large_image",
});

if (process.client) {
  const user = useCurrentUser();
  const router = useRouter();
  const route = useRoute();
  const { $paddle } = useNuxtApp();

  onMounted(() => {
    watch(user, (user, prevUser) => {
      if (user) {
        // Login
        setUser({
          id: user.uid,
          username: user.displayName,
          email: user.email,
        });
      } else if (prevUser) {
        // Logout
        if (!["/", "/delete"].includes(route.path)) {
          router.push("/");
        }
        if ($paddle) {
          $paddle.Update({ pwCustomer: {} });
        }
        setUser(null);
      }
    });
  });
}
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
  </div>
</template>
