import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@2_havvpws2erv3q2u7bxicgni2ae/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}