<script setup lang="ts">
import type { NavItem } from "@nuxt/content/dist/runtime/types";
import ContentSearch from "@nuxt/ui-pro/modules/pro/runtime/components/content/ContentSearch.vue";
import { useCurrentUser, useFirebaseAuth } from "vuefire";

const auth = useFirebaseAuth();
const user = useCurrentUser();
const router = useRouter();
const logout = () => auth && auth.signOut().then(() => router.push("/"));
const { toggleContentSearch } = useUIState();

const navigation = inject<Ref<NavItem[]>>("navigation", ref([]));

/*const links = [
  {
    label: "Console",
    to: "/console",
    children: [
      {
        label: "Console",
        to: "/console",
      },
      {
        label: "Realtime Dashboard",
        to: "/console/dashboard",
      },
      {
        label: "Command History",
        to: "/console/history",
        badge: "New",
      },
      {
        label: "Rental Dashboard",
        to: "/console/rental",
        badge: "New",
      },
      {
        label: "Support",
        to: "https://teslemetry.atlassian.net/servicedesk/customer/portal/1",
        external: true,
      },
      {
        label: "Status",
        to: "https://status.teslemetry.com",
        external: true,
      },
    ],
  },
  {
    label: "Documentation",
    to: "/docs",
  },
  {
    label: "Pricing",
    to: "/pricing",
  },
  {
    label: "Partners",
    to: "/partners",
    children: [
      {
        label: "Tint.ai",
        to: "/partners/tint",
      },
    ],
  },
  {
    label: "Blog",
    to: "/blog",
  },
  {
    label: "Support",
    to: "https://teslemetry.atlassian.net/servicedesk/customer/portal/1",
  },
];*/

const tree = mapContentNavigation(navigation.value);
</script>

<template>
  <UHeader :links="tree">
    <template #logo>
      <img
        src="~/assets/brandmark/ELECTRIC.svg"
        alt="Teslemetry Brandmark"
        class="w-auto h-6 align-middle inline-block sm:hidden"
      />
      <img
        src="~/assets/wordmark/ELECTRIC.svg"
        alt="Teslemetry Wordmark"
        class="w-auto h-6 align-middle hidden sm:inline-block"
      />
    </template>

    <template #right>
      <UButton
        icon="i-heroicons-magnifying-glass"
        variant="ghost"
        color="black"
        @click="toggleContentSearch"
      />

      <UColorModeButton size="sm" />

      <ClientOnly>
        <UButton
          v-if="user"
          label="Logout"
          color="gray"
          icon="i-heroicons-arrow-left-20-solid"
          @click="logout"
        >
          <template #leading v-if="user.photoURL">
            <UAvatar :src="user.photoURL" size="2xs" />
          </template>
        </UButton>
        <UButton
          v-else
          label="Login with Tesla"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          to="/login"
          class="hidden lg:flex"
        />
      </ClientOnly>
    </template>

    <template #panel>
      <UNavigationTree :links="tree" default-open />
    </template>
  </UHeader>
</template>
