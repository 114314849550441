import payload_plugin_qksI75aAfjKqAUwBphZwE3nor1A00gjQxQHpbda7Odo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_firebase@11.6.0_magicast@0_2figsg6dde3nmwqjwgcyjfje5m/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client__zgkF6o2KyMvPWoMKzXGncnQYMC_dp19VzKflZoDdYI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lgHoxHurMxS_CLSJiIMDmbp_nroy4a2RLefZ7So2h7w from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i7Xbmg5LaxnL0NTmkoWvAwzoBfgBakwIVSWc4NaZlCQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_qfo9XXfGZ1jXdinas7tl3zZnoilgai3bflr0sg2721Y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_snEbEVhVr3iVev5z4jpvHykbM2AdzIH00mugCBDTESA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_M__Lwq6AWNYiIw9m__VLmy6lKRQSo754PEigpY6NKkc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pcZO6YInbum1P7ICb1YZc9dXS4ymovemmZOTmTIQLVQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/opt/buildhome/repo/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_j2YaFuMZFKFHrlFEUgTu9LVluDmsqO_T3wqf44K5Ams from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_hpxQ5lBgVf7kCm5fnSAt_E5W_jligXKpQGV_hVCLl1Y from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@9.10.1_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@opent_yxj4yrqtamr55cpvyohhvkghri/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client__y5xoduWk66EmBK681Uo3PGDPZpi6OJ808VwNY6v_Jc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@13.2.0_firebase@11.6.0_magicast@0_2figsg6dde3nmwqjwgcyjfje5m/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_5mchQbrFji2Q4C_SfSquLdjPW_bEWAXa5RNJAOVNHR0 from "/opt/buildhome/repo/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import slideovers_00cWLJ1XrNLGIPAww9Dvyh6THWSzsLcvOINAgK4Bxok from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@22.1_rairzjurxb445to7pc53aoztzm/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_rjaCjRi419QvIkaaCNc1syRHH8M_vFNNTkud3_oHJkk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@22.1_rairzjurxb445to7pc53aoztzm/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_mwXE1qQKMS580VPMOWkjmH2rmcUwhjTy_wtyYx1fjhU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@22.1_rairzjurxb445to7pc53aoztzm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_0cj6lvWDTAbkOCqG2wa05Pi7oLsJKlvzDxm_YeDXKmc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.11_jiti@2.4.2_terser@5.39.0_yam_6updemmgdcsmoa56y5l7e7d5e4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_p2fNnvwO7rxlNwK9ipPB0gdH7_vYTh8JuUsCKZcMjOM from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@2_havvpws2erv3q2u7bxicgni2ae/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_qmoAE4yFEVX_aukvCaW6AKqtrPveYGBF_tPzvFQ5xTQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@2_havvpws2erv3q2u7bxicgni2ae/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_G91mxndjiBriV5EptEjIPLtiQpedbvdAn1fVxZ6OMto from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2.2_@types+node@2_havvpws2erv3q2u7bxicgni2ae/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import paddleJS_client_pPZl6wOFNYsaqRAcirOWWA3oKLGq2IE0mghSwLUhgsI from "/opt/buildhome/repo/plugins/paddleJS.client.ts";
export default [
  payload_plugin_qksI75aAfjKqAUwBphZwE3nor1A00gjQxQHpbda7Odo,
  revive_payload_client__zgkF6o2KyMvPWoMKzXGncnQYMC_dp19VzKflZoDdYI,
  unhead_lgHoxHurMxS_CLSJiIMDmbp_nroy4a2RLefZ7So2h7w,
  router_i7Xbmg5LaxnL0NTmkoWvAwzoBfgBakwIVSWc4NaZlCQ,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_qfo9XXfGZ1jXdinas7tl3zZnoilgai3bflr0sg2721Y,
  navigation_repaint_client_snEbEVhVr3iVev5z4jpvHykbM2AdzIH00mugCBDTESA,
  check_outdated_build_client_M__Lwq6AWNYiIw9m__VLmy6lKRQSo754PEigpY6NKkc,
  chunk_reload_client_pcZO6YInbum1P7ICb1YZc9dXS4ymovemmZOTmTIQLVQ,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_j2YaFuMZFKFHrlFEUgTu9LVluDmsqO_T3wqf44K5Ams,
  sentry_client_hpxQ5lBgVf7kCm5fnSAt_E5W_jligXKpQGV_hVCLl1Y,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_client__y5xoduWk66EmBK681Uo3PGDPZpi6OJ808VwNY6v_Jc,
  vuefire_auth_plugin_client_5mchQbrFji2Q4C_SfSquLdjPW_bEWAXa5RNJAOVNHR0,
  vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M,
  slideovers_00cWLJ1XrNLGIPAww9Dvyh6THWSzsLcvOINAgK4Bxok,
  modals_rjaCjRi419QvIkaaCNc1syRHH8M_vFNNTkud3_oHJkk,
  colors_mwXE1qQKMS580VPMOWkjmH2rmcUwhjTy_wtyYx1fjhU,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_0cj6lvWDTAbkOCqG2wa05Pi7oLsJKlvzDxm_YeDXKmc,
  scrollbars_client_p2fNnvwO7rxlNwK9ipPB0gdH7_vYTh8JuUsCKZcMjOM,
  presets_qmoAE4yFEVX_aukvCaW6AKqtrPveYGBF_tPzvFQ5xTQ,
  variables_G91mxndjiBriV5EptEjIPLtiQpedbvdAn1fVxZ6OMto,
  paddleJS_client_pPZl6wOFNYsaqRAcirOWWA3oKLGq2IE0mghSwLUhgsI
]