import { initializePaddle } from "@paddle/paddle-js";

export default defineNuxtPlugin({
  name: "paddle",
  parallel: true,
  async setup(nuxtApp) {
    return initializePaddle({
      token: "live_e833b39e1f38b8fe68ebeeff0c0",
      environment: "production",
      pwCustomer: {},
    }).then(
      (paddle) => ({
        provide: {
          paddle,
        },
      }),
      (e) => {
        console.error(e);
        return {
          provide: {
            paddle: undefined,
          },
        };
      },
    );
  },
});
