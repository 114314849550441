
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as keyEvsyEJEdEWN0erPGQUAzFobeZf3fcq32FFBmoDoA15EMeta } from "/opt/buildhome/repo/pages/key.vue?macro=true";
import { default as indexBeHzAQikKrSJqk6imgHKGnYps5J2m1dpAlMAyv_MDnQMeta } from "/opt/buildhome/repo/pages/blog/index.vue?macro=true";
import { default as _91slug_93TVXfQgR7E_1etoF9MB3cfpp6TKNvvtjRnrJxWZr59DgMeta } from "/opt/buildhome/repo/pages/blog/[slug].vue?macro=true";
import { default as bloguNGut8OsK3QrFWTu_cCHIcQ_457Zak81_avDcBTVOgbEEMeta } from "/opt/buildhome/repo/pages/blog.vue?macro=true";
import { default as _91_46_46_46slug_93yzHiQlKkvRcLWbiAfnAb5WHEpZZzlU82tG0DTIZLvBAMeta } from "/opt/buildhome/repo/pages/docs/[...slug].vue?macro=true";
import { default as docsPnGW6tMww3cXiKH__8sQj4WP6KLZI8tIKg1iKgf7J6gMeta } from "/opt/buildhome/repo/pages/docs.vue?macro=true";
import { default as errorHLFY7zwFUrfpprhDfpUcz1_Gwa8d9C_XhlejYbumvDkMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as loginKMdcZdjxVWBby7qzc8iax1m1i6js8gN3t8PvZM_RwR8Meta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as deletesg2jGy28CFFdSk7nvbtuAAOXEIE_45cZI03vuY5Jo6jf8Meta } from "/opt/buildhome/repo/pages/delete.vue?macro=true";
import { default as pricingq7hLQznf5sDmVmp1DnapVh77taqjuEWxS1X0_40g810Meta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as checkoutIe3ZCYBfE0_eVJtM1LwepQWKGXxfXpMBIyaOCzPKyxMMeta } from "/opt/buildhome/repo/pages/checkout.vue?macro=true";
import { default as redirectMLsXB6AUXn_45iEANeU8RqK57wkJ7NRSHGgFlBxMsGb7IMeta } from "/opt/buildhome/repo/pages/redirect.vue?macro=true";
import { default as teslabill3f5QMXNr6vCKB_45RsSkdEHpKVAICSK0VIfffKgytDiWcMeta } from "/opt/buildhome/repo/pages/teslabill.vue?macro=true";
import { default as emaileQX5x5AZoVDLTCH08SamOHbJYU2J5L5jwangjGWtxjYMeta } from "/opt/buildhome/repo/pages/admin/email.vue?macro=true";
import { default as indexLpA_45enE7H_45gCt1CVZrtW6_TonNg0AO233DpYOSBdY68Meta } from "/opt/buildhome/repo/pages/admin/index.vue?macro=true";
import { default as _91vin_93fa0_DjN6NriC_dwXySDVCNNndpT4Abl6NNoOnfJguLoMeta } from "/opt/buildhome/repo/pages/console/[vin].vue?macro=true";
import { default as indexBf3B_Q4AL4LCJaAJhv3WkgfP2mfdjiB05rGEaYQPPssMeta } from "/opt/buildhome/repo/pages/console/index.vue?macro=true";
import { default as tintostcYed0yKSFznst6hfMATtFy64UULHgBe5LzuxBZuYMeta } from "/opt/buildhome/repo/pages/partners/tint.vue?macro=true";
import { default as rentalNcJshh7riDuIfAvYgqCTXDKQPk7h0UQB45_45r2FRK_fQMeta } from "/opt/buildhome/repo/pages/console/rental.vue?macro=true";
import { default as index1_45lo4LPCCF04WUenFF8zK6xVXvDoCU064OIdw35mQIMMeta } from "/opt/buildhome/repo/pages/partners/index.vue?macro=true";
import { default as _91_46_46_46slug_93vY8ry_45_45VUnCBGH5_45BUKpNPF_n_456SKVUilCPZO4_45zW3gMeta } from "/opt/buildhome/repo/pages/api/1/[...slug].vue?macro=true";
import { default as historyA3D7IEOk3ly0gV0_45u9P0kcpQ0raJuEL8Y1hSCHB6O9wMeta } from "/opt/buildhome/repo/pages/console/history.vue?macro=true";
import { default as dashboard_c_45GPJCLo2IMevP218TfQZ2JigEhKt3_vONgC1UH49oMeta } from "/opt/buildhome/repo/pages/console/dashboard.vue?macro=true";
import { default as index7YF9wGP_ndys6qKyaOvwleoE2VcoMBBb5ax46bM4R8kMeta } from "/opt/buildhome/repo/pages/obs/[vin]/[signal]/index.vue?macro=true";
import { default as component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MYMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MY } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.11_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_erku7yfr6uskl62gfzcsau7mxe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "key",
    path: "/key",
    component: () => import("/opt/buildhome/repo/pages/key.vue")
  },
  {
    name: bloguNGut8OsK3QrFWTu_cCHIcQ_457Zak81_avDcBTVOgbEEMeta?.name,
    path: "/blog",
    component: () => import("/opt/buildhome/repo/pages/blog.vue"),
    children: [
  {
    name: "blog",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/blog/index.vue")
  },
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/opt/buildhome/repo/pages/blog/[slug].vue")
  }
]
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/opt/buildhome/repo/pages/docs.vue"),
    children: [
  {
    name: "docs-slug",
    path: ":slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/docs/[...slug].vue")
  }
]
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/opt/buildhome/repo/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginKMdcZdjxVWBby7qzc8iax1m1i6js8gN3t8PvZM_RwR8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "delete",
    path: "/delete",
    component: () => import("/opt/buildhome/repo/pages/delete.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/opt/buildhome/repo/pages/pricing.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutIe3ZCYBfE0_eVJtM1LwepQWKGXxfXpMBIyaOCzPKyxMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectMLsXB6AUXn_45iEANeU8RqK57wkJ7NRSHGgFlBxMsGb7IMeta || {},
    component: () => import("/opt/buildhome/repo/pages/redirect.vue")
  },
  {
    name: "teslabill",
    path: "/teslabill",
    component: () => import("/opt/buildhome/repo/pages/teslabill.vue")
  },
  {
    name: "admin-email",
    path: "/admin/email",
    meta: emaileQX5x5AZoVDLTCH08SamOHbJYU2J5L5jwangjGWtxjYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/email.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexLpA_45enE7H_45gCt1CVZrtW6_TonNg0AO233DpYOSBdY68Meta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/index.vue")
  },
  {
    name: "console-vin",
    path: "/console/:vin()",
    meta: _91vin_93fa0_DjN6NriC_dwXySDVCNNndpT4Abl6NNoOnfJguLoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/[vin].vue")
  },
  {
    name: "console",
    path: "/console",
    meta: indexBf3B_Q4AL4LCJaAJhv3WkgfP2mfdjiB05rGEaYQPPssMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/index.vue")
  },
  {
    name: "partners-tint",
    path: "/partners/tint",
    component: () => import("/opt/buildhome/repo/pages/partners/tint.vue")
  },
  {
    name: "console-rental",
    path: "/console/rental",
    meta: rentalNcJshh7riDuIfAvYgqCTXDKQPk7h0UQB45_45r2FRK_fQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/rental.vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/opt/buildhome/repo/pages/partners/index.vue")
  },
  {
    name: "api-1-slug",
    path: "/api/1/:slug(.*)*",
    meta: _91_46_46_46slug_93vY8ry_45_45VUnCBGH5_45BUKpNPF_n_456SKVUilCPZO4_45zW3gMeta || {},
    component: () => import("/opt/buildhome/repo/pages/api/1/[...slug].vue")
  },
  {
    name: "console-history",
    path: "/console/history",
    meta: historyA3D7IEOk3ly0gV0_45u9P0kcpQ0raJuEL8Y1hSCHB6O9wMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/history.vue")
  },
  {
    name: "console-dashboard",
    path: "/console/dashboard",
    meta: dashboard_c_45GPJCLo2IMevP218TfQZ2JigEhKt3_vONgC1UH49oMeta || {},
    component: () => import("/opt/buildhome/repo/pages/console/dashboard.vue")
  },
  {
    name: "obs-vin-signal",
    path: "/obs/:vin()/:signal()",
    meta: index7YF9wGP_ndys6qKyaOvwleoE2VcoMBBb5ax46bM4R8kMeta || {},
    component: () => import("/opt/buildhome/repo/pages/obs/[vin]/[signal]/index.vue")
  },
  {
    name: component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MYMeta?.name,
    path: "/api/fields.json",
    component: component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MY
  },
  {
    name: component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MYMeta?.name,
    path: "/feature/*",
    component: component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MY
  },
  {
    name: component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MYMeta?.name,
    path: "/qr",
    component: component_45stubmMw8Akg7ZHjjsfCsM2pwx4qmwrAZkoxen8qob1_45X7MY
  }
]